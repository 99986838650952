import request from '@/request'
import { BASE_URL } from '@/config/app'

export const UPLOAD_TEAM_IMAGES = BASE_URL + '/team/upload'

/**
 * 团队成员列表
 * @returns {Promise<AxiosResponse<any>>}
 */
export const teamList = (params) => {
  return request.get('/team', {
    params
  })
}

/**
 * 团队成员详情
 * @returns {Promise<AxiosResponse<any>>}
 */
export const teamInfo = (params) => {
  return request.get('/team/info', {
    params
  })
}

/**
 * 团队成员创建
 * @returns {Promise<AxiosResponse<any>>}
 */
export const teamCreate = (data) => {
  return request.post('/team/create', data)
}

/**
 * 团队成员编辑
 * @returns {Promise<AxiosResponse<any>>}
 */
export const teamEdit = (data) => {
  return request.post('/team/edit', data)
}

/**
 * 团队成员删除
 * @returns {Promise<AxiosResponse<any>>}
 */
export const teamDelete = (data) => {
  return request.post('/team/delete', data)
}

/**
 * 排序
 */
export const teamSort = (data) => {
  return request.post('/team/sort', data)
}

import request from '@/request'
import { BASE_URL } from '@/config/app'

/**
 * editor上传图片
 * @type {string}
 */
export const UPLOAD_IMAGE_API = BASE_URL + '/uploadImages'
/**
 * 获取设置
 * @returns {Promise<AxiosResponse<any>>}
 */
export const setting = (params) => {
  return request.get('/setting', {
    params
  })
}

/**
 * 保存设置
 * @returns {Promise<AxiosResponse<any>>}
 */
export const settingSave = (data) => {
  return request.post('/setting/save', data)
}

// ckeditor 配置文件
import { UPLOAD_IMAGE_API } from '@/request/modules/setting'

export const EDITOR_CONFIG = {
  image: {
    upload: {
      types: ['jpg', 'png', 'jpeg', 'svg', 'gif']
    }
  },
  ckfinder: {
    uploadUrl: UPLOAD_IMAGE_API,
    withCredentials: true,
    openerMethod: 'popup',
    id: 'file'
  }
}
